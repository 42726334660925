'use strict';

import Validate from 'validate.js';

export default class Signup {
  constructor() {
    const form = document.querySelector('.js-form-validation');
    const dataJS = {
      'access_token': '1hkkj2ehfe92lnsmbtmbt43l'
    };
    const lightbox = document.querySelector('.lightbox');
    const constraints = {
      name: {
        presence: {
          message: 'is a required field.'
        }
      },
      'business_name': {
        presence: {
          message: 'is a required field.'
        }
      },
      email: {
        presence: {
          message: 'is a required field.'
        },
        email: {
          message: 'must be a valid email address.'
        }
      },
      phone: {
        presence: {
          message: 'is a required field.'
        }
      },
      address1: {
        presence: {
          message: 'is a required field.'
        }
      },
      zip: {
        presence: {
          message: 'is a required field.'
        }
      },
      state: {
        presence: {
          message: 'is a required field.'
        }
      }
    };

    document.querySelector('.lightbox__close').addEventListener('click', () => {
      lightbox.classList.add('lightbox--removed');
    }, false);

    function onSuccess() {
      window.location = window.location.pathname + 'success/';
    }

    function onError(error) {
      window.location = window.location.pathname + 'failure/';
    }

    const inputElements = document.querySelectorAll('.fm__input');
    inputElements.forEach(element => {
      element.addEventListener('input', function () {
        if (element.value !== '') {
          element.classList.add('fm__input--has-value');
        } else {
          element.classList.remove('fm__input--has-value');
        }
      });
    });

    const selectElements = document.querySelectorAll('.fm__select-el');
    selectElements.forEach(element => {
      element.addEventListener('input', function () {
        if (element.value !== '') {
          element.classList.add('fm__input--has-value');
        } else {
          element.classList.remove('fm__input--has-value');
        }
      });
    });

    function showErrors(errors) {
      let html = '';
      for (let prop in errors) {
        html += `<li>${errors[prop][0]}</li>`;
      }
      let errorsList = document.querySelector('.errors__list');
      errorsList.innerHTML = html;
      lightbox.classList.remove('lightbox--removed');
    }

    function sendEmail(form) {
      const request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == 4 && request.status == 200) {
          onSuccess();
        } else
        if (request.readyState == 4) {
          onError(request.response);
        }
      };
      
      dataJS['subject'] = '[Form Submitted] Card Connect Solutions Contact';
      dataJS['text'] = constructMessage(form);
      const params = toParams(dataJS);

      request.open('POST', 'https://postmail.invotes.com/send', true);
      request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

      request.send(params);

      return false;
    }

    form.addEventListener('submit', function (ev) {
      ev.preventDefault();
      handleFormSubmit(form);
    });

    function handleFormSubmit(form) {
      var errors = new Validate(form, constraints);
      if (Object.keys(errors).length === 0) {
        sendEmail(form);
      } else {
        showErrors(errors);
      }
    }

    function toParams(dataJS) {
      var formData = [];
      for (var key in dataJS) {
        formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(dataJS[key]));
      }
      return formData.join('&');
    }

    function constructMessage(form) {
      let message = '';
      for (let i = 0; i < form.length; i++) {
        const formElement = form[i];
        let setMessage = false;
        if (formElement.nodeName === 'INPUT' || formElement.nodeName === 'SELECT') {
          setMessage = true;
          if (formElement.type === 'checkbox' || formElement.type === 'radio' && !formElement.checked) {
            setMessage = false;
          }
        }
        if (setMessage) {
          message += `${formElement.name}: ${formElement.value}\r\n`;
        }
      }
      return message;
    }
  }
}
