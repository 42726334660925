'use strict';

export default class Products {
  constructor() {
    this.addListener = this.addListener.bind(this);
    this.productButtonClicked = this.productButtonClicked.bind(this);
    this.iterateBtns(this.addListener);
  }

  productButtonClicked(e) {
    this.iterateBtns(this.resetBtns);

    let btn = e.target;
    if (btn.nodeName !== 'BUTTON') {
      btn = btn.closest('button');
    }
    this.selectProduct(btn);
  }

  iterateBtns(callback) {
    const productButtons = document.getElementsByClassName('js-product-btn');

    for (let i = 0; i < productButtons.length; i++) {
      const btn = productButtons[i];
      callback(btn);
    }
  }

  addListener(btn) {
    btn.addEventListener('click', this.productButtonClicked, false);
  }

  resetBtns(btn) {
    btn.classList.remove('text-teal', 'shadow-lg');
    btn.classList.add('text-grey-dark', 'shadow-sm');
  }

  selectProduct(btn) {
    btn.classList.remove('text-grey-dark', 'shadow-sm');
    btn.classList.add('text-teal', 'shadow-lg');

    const target = btn.getAttribute('data-target');
    const products = document.getElementsByClassName('js-product');

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      product.classList.remove('is-selected');
    }
    document.getElementById(target).classList.add('is-selected');
  }
}
