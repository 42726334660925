'use strict';

const header = document.getElementById('js-header');
const subNav = document.getElementById('js-subnav');
const solutionsNav = document.getElementsByClassName('nav__item--solutions')[0];

export default class Header {
  constructor() {
    this.toggleSubNav = this.toggleSubNav.bind(this);

    solutionsNav.addEventListener('mouseenter', this.showSubNav, false);
    solutionsNav.addEventListener('click', this.toggleSubNav, false);

    subNav.addEventListener('mouseleave', this.hideSubNav, false);
  }

  showSubNav() {
    subNav.classList.add('active', 'show');
    header.classList.add('header--subnav-active');
    solutionsNav.getElementsByTagName("A")[0].classList.add('active');

  }

  hideSubNav() {
    subNav.classList.remove('active', 'show');
    header.classList.remove('header--subnav-active');
    solutionsNav.getElementsByTagName("A")[0].classList.remove('active');
  }

  toggleSubNav() {
    if (header.classList.contains('header--subnav-active')) {
      this.hideSubNav();
    } else {
      this.showSubNav();
    }
  }
}
