'use strict';

import Flickity from 'flickity';

export default class Features {
  constructor() {
    this.addListener = this.addListener.bind(this);
    this.featureButtonClicked = this.featureButtonClicked.bind(this);
    this.selectFeature = this.selectFeature.bind(this);
    this.iterateBtns(this.addListener);

    const elem = document.querySelector('.flickity-slider');

    this.flkty = new Flickity( elem, {
      wrapAround: true
    });

    this.flkty.on( 'change', index => {
      const cellElements = this.flkty.getCellElements();
      for (let i = 0; i < cellElements.length; i++) {
        cellElements[i].querySelector('.w-full').classList.add('opacity-40', 'scale-0-9');
      }
      cellElements[index].querySelector('.w-full').classList.remove('opacity-40', 'scale-0-9');
      this.selectFeatureButton(index);
    });
  }

  iterateBtns(callback) {
    const featureNav = document.querySelector('.features-nav');
    if (featureNav) {
      const featureButtons = featureNav.querySelectorAll('button');
      for (let i = 0; i < featureButtons.length; i++) {
        const btn = featureButtons[i];
        callback(btn);
      }
    }
  }

  addListener(btn) {
    btn.addEventListener('click', this.featureButtonClicked, false);
  }

  resetButtons(btn) {
    btn.classList.remove('text-teal', 'border-teal-light', 'shadow-teal-sm');
    btn.classList.add('text-grey-dark', 'border-grey-light', 'shadow-sm');
  }

  selectFeatureButton(index) {
    this.iterateBtns(this.resetButtons);
    const featureButtons = document.querySelector('.features-nav').querySelectorAll('button');
    const btn = featureButtons[index];
    btn.classList.add('text-teal', 'border-teal-light', 'shadow-teal-sm');
    btn.classList.remove('text-grey-dark', 'border-grey-light', 'shadow-sm');
  }

  selectFeature(btn) {
    const featureButtons = document.querySelector('.features-nav').querySelectorAll('button');
    const index = Array.from(featureButtons).indexOf(btn);
    this.flkty.select( index, true, false );
    this.selectFeatureButton(index);
  }

  featureButtonClicked(e) {
    let btn = e.target;
    if (btn.nodeName !== 'BUTTON') {
      btn = btn.closest('button');
    }
    this.selectFeature(btn);
  }
}
