// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';

import Header from '../_modules/header/header';
import Products from '../_modules/products/products';
import Features from '../_modules/cardpointe/features';
import Signup from '../_modules/signup/signup';

$(() => {
  new Header();

  const path = window.location.pathname.split('/');
  switch (path[path.length - 2]) {
    case '':
      new Products();
      break;
    case 'transaction-management':
      new Features();
      setupVideo();
      break;
    case 'terminal':
      new Features();
      break;
    case 'virtual-terminal':
      new Features();
      setupVideo();
      break;
    case 'mobile':
      new Features();
      setupVideo();
      break;
    case 'hpp':
      new Features();
      setupVideo();
      break;
    case 'signup':
      new Signup();
      break;

    default:
      break;
  }

  function setupVideo() {
    const videoContainer = document.querySelector('.cardpointe__hero-video');
    videoContainer.addEventListener('click', () => {
      let video = document.querySelector('video');
      if (video.paused) {
        video.play();
        videoContainer.querySelector('.bg-grey-faded').classList.add('opacity-0');
      } else {
        video.pause();
        videoContainer.querySelector('.bg-grey-faded').classList.remove('opacity-0');
      }
    }, false);
  }
});
